window.jQuery = $
window.$ = $

document.addEventListener("turbolinks:load", function() {
  $('.clientByEmail').on('blur', function() {
    let element = $(this);
    let clientUrl = element.data('client-url');
    let token = $('meta[name="csrf-token"]').attr('content');
    let email = element.val();
    let clientIdEl = $('#construction_client_id');

    clientIdEl.val('');

    if(!element.valid() || element.val().length < 1) {
      return;
    }

    $.ajax({
      url: clientUrl,
      type: 'GET',
      data: { email: email },
      beforeSend: function(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token);
      },
      success: function(response) {
        if(response.status == 'not_found') {
          $('.clientForm label, .clientForm input').each(function() {
            let el = $(this);

            el.attr('disabled', false);
          })
        } else {
          let nameEl = $('#construction_client_name').val(response.client.name);

          clientIdEl.val(response.client.id);
        }
      }
    });
  })
});

import 'select2'
import ClipboardJS from 'clipboard'
import c3 from 'c3'
import { QrCodePix } from 'qrcode-pix';
import '../limitless/datatables.min'

window.jQuery = $
window.$ = $

document.addEventListener("turbolinks:before-cache", function () {
  $('.form-control-select2').select2('destroy')
  $.uniform.restore($(".form-control-uniform"))
  $('.datatable-scroll-y').DataTable().destroy();
  $('.datatable-scroll-without-default-order').DataTable().destroy();
})

document.addEventListener("turbolinks:load", function () {
  // Select2 init
  $('.form-control-select2').select2({ tags: true });

  $('.form-control-select2.form-control-select2-tags').select2({
    tags: true,
    dropdownParent: $('.select2-tags-container')
  });

  // Uniform style
  $('.form-control-uniform').uniform();

  $('#orders-list-status-filter').on('change', (event) => {
    if ($(event.target).val()) {
      Turbolinks.visit('/admin/orders/filter/' + $(event.target).val())
    } else {
      Turbolinks.visit('/admin/orders')
    }
  })

  $('#toggle-checkbox-items').on('change', (event) => {
    let checked = $(event.target).is(':checked')

    $('.item-checkbox').prop('checked', checked)
    $('.item-checkbox').trigger('change')
  })

  $('.item-checkbox').on('change', () => {
    let any_checkbox_checked = $('.item-checkbox').is(':checked')

    $('#checkboxes-form-submit').prop('disabled', !any_checkbox_checked)
  })

  $('.address_state_change').on('change', (event) => {
    $.get('/ibge/cities/' + $(event.target).val())
  })

  $('.address_city_code_change').on('change', (event) => {
    $('.address_city_value').val($('.address_city_code_change option:selected').text())
  })

  $('#invoices-list-status-filter, #invoices-list-users-filter').on('change', (event) => {
    let status = $('#invoices-list-status-filter').val()
    let user_id = $('#invoices-list-users-filter').val()

    Turbolinks.visit('/admin/invoices?status=' + status + '&user_id=' + user_id)
  })

  $('[data-toggle="tooltip"]').tooltip()

  new ClipboardJS('.btn-tooltip')

  $('.btn-tooltip').on('show.bs.tooltip', function (e) {
    if ($(e.target).data('trigger') === 'click') {
      setTimeout(function () {
        $(e.target).tooltip('hide')
      }, 1000)
    }
  })

  const rating_dashboard_chart = document.getElementById('rating-dashboard-chart')

  if (rating_dashboard_chart) {
    c3.generate({
      bindto: rating_dashboard_chart,
      size: { width: 350 },
      color: {
        pattern: ['#56B03E', '#FFC000', '#F0462D']
      },
      data: {
        columns: [
          ['Promotores', rating_dashboard_chart.dataset.good],
          ['Neutros', rating_dashboard_chart.dataset.neutral],
          ['Detratores', rating_dashboard_chart.dataset.bad],
        ],
        type: 'donut'
      },
      donut: {
        title: ""
      }
    });
  }

  // Makes browser alert a message if something is filled in the form and not fully submitted
  if ($('#form-order').length > 0) {
    $(window).on('beforeunload', function (event) {
      return $('#form-order').find('button[type=submit]').attr('disabled')
    })
  }

  if ($('#order_user_pix_key').length > 0) {
    qrCodeImg();
  }

  $('.datatable-scroll-y').DataTable({
    autoWidth: true,
    scrollY: 300,
    paging: false,
    info: false,
    searching: false,
    order: [[3, 'desc']]
  });

  $('.datatable-scroll-without-default-order').DataTable({
    autoWidth: true,
    scrollY: 360,
    paging: false,
    info: false,
    searching: false,
    order: []
  });

  $('.mark-all-notification').on('click', function (e) {
    e.preventDefault();

    let ids = [];
    let btnEl = $(this);
    let notificationUrl = btnEl.data('notificationUrl');
    let notificationType = btnEl.data('notificationType');

    btnEl.closest('.dropdown-menu').find('.notification-not-read').each(function (_, el) {
      ids.push($(el).data('notificationId'));
    })

    $.ajax({
      url: notificationUrl,
      method: 'POST',
      data: {
        ids: ids,
        type: notificationType
      }
    }).done(function () {
      btnEl.closest('.dropdown-menu').find('.notification-not-read').each(function (_, el) {
        $(el).removeClass('font-weight-bold');
      });

      $(`.notification-badge-${notificationType}`).html('0');
    });
  })

  $('#btn-archive').on('click', function () {
    let invoice_ids = inputsChecked();
    let archive = true;
    archiveOrUnarchive(invoice_ids, archive);
  })

  $('#btn-unarchive').on('click', function () {
    let invoice_ids = inputsChecked();
    let archive = false;
    archiveOrUnarchive(invoice_ids, archive);
  })
})

async function qrCodeImg() {
  const qrCodePix = QrCodePix({
    version: '01',
    key: String($('#order_user_pix_key').data('pix-key')),
    name: $('#order_user_pix_key').data('user-name'),
    city: $('#order_user_pix_key').data('user-city'),
    transactionId: $('#order_user_pix_key').data('transaction-id'),
    message: $('#order_user_pix_key').data('message'),
    cep: $('#order_user_pix_key').data('user-zipcode'),
    value: parseFloat($('#order_user_pix_key').data('value')),
  });

  $('#order_qr_code').html(qrCodePix.payload());

  const src = await qrCodePix.base64();
  $('#order_qr_code_img').attr('src', src);
}

function inputsChecked() {
  let inputs = $('.item-checkbox')
  let invoice_ids = []

  for (let i = 0; i < inputs.length; i++) {
    if ($(inputs[i]).is(":checked")) {
      let id = $(inputs[i]).val();
      invoice_ids.push(id)
    }
  }

  if (invoice_ids.length == 0) {
    alert('Nenhuma nota foi selecionada.')
    return false
  }

  return invoice_ids
}

function archiveOrUnarchive(invoice_ids, bool) {
  $.ajax({
    url: "invoices/archive",
    method: 'POST',
    data: {
      invoice: {
        invoice_ids: invoice_ids,
        archive: bool
      }
    }
  }).done(function () {
    window.location.reload();
  });
}
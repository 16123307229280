window.jQuery = $;
window.$ = $;

document.addEventListener("turbolinks:load", function() {
  let orderUrl = $('.orders-select-remote').data('order-url');
  let token = $('meta[name="csrf-token"]').attr('content');

  function bindingClearForm() {
    $('.clear').on('click', function() {
      let form = $(this).closest('form');

      clearForm(form);
    });
  }

  function bindingValidateForm() {
    $('.validate').validate({errorClass: 'text-danger'});
    $('.validate').on('submit', function() {
      let field_required = true;
      let form = $(this);

      form.find('.required').each(function(){
        if($(this).val().length < 1) {
          field_required = false;
        }
      })

      if(!form.valid()) {
        return false;
      }

      if(!field_required) {
        alert('Favor preencher os campos obrigatórios');
      }

      if(form.valid() && field_required) {
        return true
      } else {
        return false
      }
    });
  }

  function formatOrders(order) {
    return `Nº OS: ${order.id}`;
  }

  $('.finished_order_wizard').on('click', function() {
    let orderUrl = $(this).data('wizard-url');
    let currentStep = $(this).data('wizard-step');

    $.ajax({
      type: "PATCH",
      url: orderUrl,
      data: {
        construction: {
          current_step: currentStep
        }
      }
    }).done(function() {
      document.location.reload(true);
    });
  })

  $('.orders-select-remote').select2({
    ajax: {
      url: orderUrl,
      dataType: 'json',
      delay: 500,
      data: function (params) {
        return {
          query: params.term,
          page: params.page
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;

        return {
            results: data.items,
            pagination: {
                more: (params.page * 30) < data.total_count
            }
        };
      },
      cache: true
    },
    escapeMarkup: function (markup) { return markup; },
    minimumInputLength: 1,
    templateResult: function(order) {
      if(!order.id) {
        return 'Aguarde...'
      }

      return `Nº OS: ${order.id} - ${order.user.name} - ${order.customer_name}`
    },
    templateSelection: formatOrders
  });

  bindingValidateForm();
  bindingClearForm();
});

const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const XHRUpload = require('@uppy/xhr-upload')
const Form = require('@uppy/form')
const ptBR = require('@uppy/locales/lib/pt_BR')

import Rails from "@rails/ujs"

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

var form_uploader = form_uploader || {};

form_uploader = (function($) {
  'use strict';

  let uppy

  function init() {
    const uploader_field = ".form-uploader-container"
    const container = $(uploader_field)
    const field = container.data('field')
    const form = $(`#${container.data('form-id')}`)

    if (container.length > 0) {
      uppy = new Uppy({
        id: `uppy_${field}`,
        debug: true,
        locale: ptBR,
        onBeforeUpload: (_files) => {
          return form.valid()
        }
      })

      uppy.use(Dashboard, {
        inline: true,
        hideUploadButton: true,
        target: uploader_field,
        height: 120,
        width: '100%'
      })

      uppy.use(XHRUpload, {
        endpoint: form.prop('action'),
        formData: true,
        fieldName: container.data('field-name'),
        bundle: true,
        responseType: container.data('response-type'),
        getResponseData: (responseText, response) => {
          if (response.responseType == 'text') {
            eval(responseText)
          } else {
            Turbolinks.visit(response.responseURL)
          }

          uppy.reset()
        }
      })

      uppy.use(Form, {
        target: `#${container.data('form-id')}`
      })

      $('.btn-upload-and-submit').on('click', function (_event) {
        if (uppy.getFiles().length > 0) {
          uppy.upload()
        } else {
          let formElement = document.querySelector(`#${container.data('form-id')}`)
          Rails.fire(formElement, 'submit');
        }
      })
    }
  }

  return {
    init: init
  };
})(jQuery);

document.addEventListener("turbolinks:load", function() {
  form_uploader.init()
})
